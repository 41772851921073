import React, { useState, useContext, useRef, useEffect } from 'react';
import './header.css';
import { AppContext } from '../AppContext';
import coinIcon from '../../../src/assets/itemcard/CZ.png';

import navListData from '../../data/navListData';
import NavListItem from '../../components/NavListItemHeader';
import logosvg from '../../assets/logo/CZSVGLOGO.svg';
import DiscordButton from '../../components/discordButton/DiscordButton';

function Header({ toggleActive, activeNavItem, setActiveNavItem, reference, active, sectionActive, onShopClick, onFactoryClick }) {
  const { credits, defaultUserImg, userImg, userName } = useContext(AppContext);
  const [menu, setMenu] = useState(false);
  const toggleMenu = () => {
    setMenu(!menu);
    toggleActive();
    window.dispatchEvent(new CustomEvent('factoryResize'));
  };

  const [navData, setNavData] = useState(navListData);
  const isInitialized = useRef(false);

    //MENU
   // Toggle the side menu
   const handleNavOnClick = (id, target) => {
    const newNavData = navData.map(nav => ({
      ...nav,
      active: nav.target === target,
    }));
    setNavData(newNavData);
  
    if (target === 'factory') {
      window.dispatchEvent(new CustomEvent('factoryResize'));
      onFactoryClick();
    } else {
      onShopClick(target);
    }
  
    setActiveNavItem(target);
  };
  

  useEffect(() => {
    const updatedNavData = navData.map(nav => ({
      ...nav,
      active: nav.target === activeNavItem,
    }));
    setNavData(updatedNavData);
  }, [activeNavItem]);

  const userProfileImage = userImg || defaultUserImg;

  //end menu

  //      <div className="d-flex align-items-center">
  //<a
 // href="#"
  //className={`menu ${menu ? 'active' : undefined}`}
  //onClick={toggleMenu}
//>
 // <i class="bi bi-menu-app"></i>
//</a>
//</div>

  return (
    <header>

      <div className="headerLogo">
        <img src={logosvg} alt="Logo" />
      </div>
      <div className={`headerMenu ${active ? 'active' : ''}`}>
      <div className='headerMenuScroll'>
      <ul className="hnav">
  {navData.map(item => (
    <NavListItem
      key={item._id}
      item={item}
      navOnClick={() => handleNavOnClick(item._id, item.target)}
      extraClassName={`${item._id === 6 ? 'factoryButton' : ''} ${item.active ? 'active' : ''}`}
    />
  ))}
</ul>

        </div>
        </div>
      <div className="userItems">

        <div className="avatar">
          <img src={userProfileImage} referrerPolicy="no-referrer" alt="User" />
        </div>
        <div className="user">
          <span>{userName}</span>
        </div>
        <div className="headerCredContainer">
          <div className='credIcon'>
            <img src={coinIcon} alt="Coin Icon" />
          </div>
          <div className='credAmount'>{credits}</div>
        </div>
      </div>
    </header>
  );
}

export default Header;
