import React, { useEffect, useState, useRef } from 'react';
import './sideMenu.css';
import navListData from '../../data/navListData';
import NavListItem from '../NavListItem';
import logosvg from '../../assets/logo/CZSVGLOGO.svg';
import DiscordButton from '../../components/discordButton/DiscordButton';

function SideMenu({ activeNavItem, setActiveNavItem, reference, active, sectionActive, onFactoryClick }) {
  
  const [navData, setNavData] = useState(navListData);
  const isInitialized = useRef(false); 

  const handleNavOnClick = (id, target) => {
    const newNavData = navData.map(nav => ({
      ...nav,
      active: nav._id === id  
    }));
    setNavData(newNavData);
  
    if (target === 'factory') {
      window.dispatchEvent(new CustomEvent('factoryResize'));
      onFactoryClick(); 
    } else {
      sectionActive(target);  
    }
  };

  useEffect(() => {
    if (!isInitialized.current) {
      const initialNavData = navData.map(nav => ({
        ...nav,
        active: nav._id === 2, 
      }));
      setNavData(initialNavData);
      isInitialized.current = true;
    } else {
      const updatedNavData = navData.map(nav => ({
        ...nav,
        active: nav._id === activeNavItem,
      }));
      setNavData(updatedNavData);
    }
  }, [activeNavItem]); 

  return (
    <div className={`sideMenu ${active ? 'active' : ''}`}>
      <div className="logo">
        <img src={logosvg} alt="Logo" />
      </div>
      <div className='sideMenuScroll'>
        <ul className="nav">
          {navData.map(item => (
            <NavListItem
              key={item._id}
              item={item}
              navOnClick={handleNavOnClick}
              extraClassName={item._id === 6 ? 'factoryButton' : ''}
            />
          ))}
        </ul>
        <ul className="centerfix">
        <div className="centermaybe">
        <DiscordButton/>
        </div>
        </ul>
      </div>
    </div>
  );
}

export default SideMenu;
