// Showcase.jsx
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import './showcase.css';
import { EffectFade, Navigation, Autoplay } from 'swiper/modules';
import showcaseCoinIcon from '../../assets/itemcard/CZ.png';

function Showcase({ games }) {
  const parts = [
    'PROP',
    'BODY',
    'HEAD',
    'PAD',
    'HAND',
    'ARM',
    'CHEST',
    'WAIST',
    'BACK',
    'LEG',
    'FOOT',
    'ENV',
  ];

  return (
    <Swiper
      effect="fade"
      navigation={true}
      loop={true}
      autoplay={{
        delay: 6000,
        disableOnInteraction: true,
      }}
      modules={[EffectFade, Navigation, Autoplay]}
      className="showcase-swiper"
    >
      {games.map((game) => (
        <SwiperSlide key={game._id}>
          <div className="showcase-slide">
            <div className="showcase-details">
            <div className='showcasePriceContainer'>
              
                <div className='showcasePriceIcon'>
                  <img src={showcaseCoinIcon} alt="Coin Icon" style={{ width: '20px' }}/>
                </div>
              
                <div className='showcasePrice'>
                  {Math.round(game.price)}
                </div>

            </div>
            <h2>{game.title}</h2>
            <div className='showcaseDescription'>{game.description}</div>
            <a className="orderBtn" href="#">
                BUY
              </a>



              <div className="inclusionChart">
                {parts.map((part) => {
                  let displayText = part;
                  let isActive = false;

                  if (part === 'BODY') {
                    displayText = game.Body || 'BODY';
                    isActive = Boolean(game.Body);
                  } else {
                    const key = part.toLowerCase();
                    isActive = game[key];
                  }

                  return (
                    <div
                      key={part}
                      className={`partID ${isActive ? 'active' : 'inactive'}`}
                    >
                      {displayText}
                    </div>
                  );
                })}
              </div>

              
            </div>
            <div className="showcase-image">
              <img src={game.img} alt={game.title} />
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default Showcase;
