import React, { useState, useEffect } from "react";
import '../css/dlcard.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import cool from "../assets/cool.png";

const Download = (props) => {
  const [showPopup, setShowPopup] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const [showDownload, setShowDownload] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    let timer;
    if (showPopup && countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setShowDownload(true); //set to true to enable downloads again
    }

    return () => clearTimeout(timer);
  }, [showPopup, countdown]);

  const handleButtonClick = () => {
    setShowPopup(true);
    setShowOverlay(true); // Show the overlay
    setCountdown(15);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    setShowOverlay(false); // Hide the overlay
    setCountdown(15);
    setShowDownload(false);
  };

  return (
    <div>

      <div
        id="Button"
        className="abs DLbutton dlButtonPos"
        onClick={handleButtonClick}
      >
        <i className="bi bi-download"></i>
        Download Citizen
      </div>

      {showOverlay && <div className="overlay"></div>} {/* Add this line to show the overlay */}

      {showPopup && (

        <div class="dlwrapper">
          <div class="dlcard">
          <div className="close-button" onClick={handlePopupClose}>
  <FontAwesomeIcon icon={faTimesCircle} />
</div>

            <h2 className="dltitle">DOWNLOAD STL</h2>
            <div class="dl-card__unit-description">
              File may need some love in Blender or your favorite slicer before printing!
            </div>

            <div class="dl-cardButton clearfix">
              <div className="timer">
                {showDownload ? (
                  <div
                    className="buttons"
                    id="Button"
                    onClick={() => {
                      window.exportToSTL(props.avatarName);
                      setShowDownload(false);
                      setCountdown(10);
                    }}
                  >
                    Download STL
                  </div>
                ) : (
                  <span>Download in - {countdown} seconds</span>
                )}
              </div>

            </div>

          </div>
        </div>
      )}
    </div>
  );
};


export default Download;
