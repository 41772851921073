import React, { useState, useEffect, useContext } from 'react';
import './home.css';
import ItemCard from '../../components/itemCard/ItemCard';
import CoinCard from '../../components/coinCard/CoinCard';
import { AppContext } from '../AppContext'; // 
import { Timeline } from 'react-twitter-widgets';
import Showcase from '../../components/showcase/Showcase';
import games from '../../api/gamesData.json';
import axios from 'axios';



function Home({ coins, reference, updateCredits }) {
  const [items, setItems] = useState([]);
  const [inventory, setInventory] = useState([]);
  const { userId } = useContext(AppContext);
  const [colClass, setColClass] = useState('col-12');
  const [activeCard, setActiveCard] = useState(null); // Manage the active card state
  const [apiEndpoint] = useState('https://czbackend.herokuapp.com/api/eb_m');
  const categories = [
    'head', 'pad', 'hand', 'arm', 'body', 'chest', 'waist', 'back', 'foot', 'leg', 'stand', 'env', 'podium', 'prop01'
  ];
  const categoryDisplayNames = {
    'head': 'Head',
    'pad': 'Pad',
    'hand': 'Hand',
    'arm': 'Arm',
    'chest': 'Chest',
    'waist': 'Waist',
    'back': 'Back',
    'foot': 'Foot',
    'leg': 'Leg',
    'stand': 'Stand',
    'env': 'Environment',
    'podium': 'Podium',
    'prop01': 'Prop'
  };

  useEffect(() => {
    const fetchAllItems = async () => {
      try {
        const promises = categories.map(category => fetchLibraryData(category));
        const results = await Promise.all(promises);
        const allItems = results.flat();

        // Sort items based on category priority
        allItems.sort((a, b) => {
          if (a.category === 'prop01' && b.category !== 'prop01') {
            return -1; // a comes before b
          } else if (a.category !== 'prop01' && b.category === 'prop01') {
            return 1; // b comes before a
          } else {
            return 0; // keep original order
          }
        });

        setItems(allItems);
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };
  
    fetchAllItems();
  
    const handleResize = () => setColClass(getColClass(window.innerWidth));
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  const fetchLibraryData = async (category) => {
    try {
      let endpoint;
  
      switch (category) {
      case 'head':
          endpoint = 'head';
          break;
      case 'pad':
          endpoint = 'pads';
          break;
      case 'waist':
          endpoint = 'waist';
          break;
      case 'back':
          endpoint = 'back';
          break;
      case 'hand':
          endpoint = 'hands';
          break;
      case 'arm':
          endpoint = 'arms';
          break;
      case 'chest':
          endpoint = 'chest';
          break;
      case 'foot':
          endpoint = 'feet';
          break;
      case 'leg':
          endpoint = 'legs';
          break;
      case 'stand':
          endpoint = 'stand';
          break;
      case 'podium':
          endpoint = 'podium';
          break;
      case 'prop01':
          endpoint = 'prop01';
          break;
        default:
          throw new Error(`Unknown category: ${category}`);
      }
  
      const url = (endpoint === 'body' || endpoint === 'prop01')
      ? `https://czbackend.herokuapp.com/api/elements/${endpoint}`
      : `${apiEndpoint}/${endpoint}`;
      const res = await axios.get(url);
  
    // Filter out items named 'None' or 'Default' and add category and displayCategory fields
    return res.data
      .filter(item => item.name && !['none', 'default'].includes(item.name.toLowerCase()))
      .map(item => ({
        ...item,
        category,
        displayCategory: categoryDisplayNames[category] || category
      }));
    } catch (err) {
      console.error(`Error fetching library data for ${category}:`, err);
      return [];
    }
  };
  
  const getColClass = (width) => {
    if (width >= 1200) {
      return 'col-lg-3';
    } else if (width >= 992) {
      return 'col-md-4';
    } else if (width >= 768) {
      return 'col-md-6';
    } else {
      return 'col-12';
    }
  };

  useEffect(() => {
    setColClass(getColClass(window.innerWidth));
  }, []);

  return (
    <section id="home" className="home active" ref={reference}>
      <div className="container-fluid">
{/* New row for the coin section */}
<div className="row" style={{ height: 'auto' }}>
  <div className="col-lg-12" style={{ paddingBottom: '10px', width: '100%' }}>
    <div className='credArea' style={{ display: 'flex', flexDirection: 'column', height: '193px', maxHeight: '335px'}}>
      <div className="row">

      </div>
      <div className="row cards" style={{ flex: 1 }}>
        {/* Coin cards */}
        {coins
          .filter(coin => coin.active === true)
          .slice(0, 8)
          .map(coin => (
            <CoinCard
            key={coin.title} // Unique key for each card
            coin={coin} // Pass coin data
            activeCard={activeCard} // Pass active card state
            setActiveCard={setActiveCard} // Pass state updater
          />
          ))}
      </div>
    </div>
  </div>
</div>
      <div className="row" style={{ height: 'auto' }}>  {/* Row for the scroll box */}
  {/* Scroll box section */}
  <div className="col-lg-12 invisascroll" style={{ display: 'flex', height: '375px', paddingBottom: '10px', }}>
    <div  style={{ width: '100%', height: '100%', maxHeight: '375px', backgroundColor: 'var(--bannerBG)', borderRadius: '10px', }}>
      {/* className="myShadow" */}
      <Showcase games={games} />
    </div>
  </div>
</div>




        <div className="row">
          <div className="col-lg-12">
            <h2 className="sectionTitle">All Items</h2>
            <p className='componentsubtext'></p>
          </div>
        </div>
        <div className="row cards">
          <div className="col">
            <div className="row">
            {items.map(item => (
                  <div key={item.id || item.item_id || item.file} className={`${colClass} extendo`}>
                    <ItemCard
                  saleItem={item}
                  category={item.category}
                  updateCredits={updateCredits}
                />
                  </div>
                ))}
            </div>
          </div>
        </div>

      </div>
    </section>

  );
}


export default Home;
