import React, { useRef, useState, useEffect } from "react";
import './coinCardSCSS.scss';

function CoinCard({ coin, activeCard, setActiveCard }) {
  const cardRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [tiltStyle, setTiltStyle] = useState({});
  const [borderStyle, setBorderStyle] = useState({});
  const [imgStyle, setImgStyle] = useState({});
  const [shineStyle, setShineStyle] = useState({});
  const [isFlipped, setIsFlipped] = useState(false);
  const styleTag = useRef(null);

  useEffect(() => {
    if (cardRef.current && activeCard === coin.title) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [activeCard, coin.title]);

  const handleMouseMove = (e) => {
    //if (isFlipped) return; // Disable tilt effects when flipped

    const card = cardRef.current;
    const cardRect = card.getBoundingClientRect();

    const centerX = cardRect.left + cardRect.width / 2;
    const centerY = cardRect.top + cardRect.height / 2;

    const mouseX = e.clientX - centerX;
    const mouseY = e.clientY - centerY;

    // Calculate tilt angles
    const rotateX = (mouseY / cardRect.height) * 50;
    const rotateY = -(mouseX / cardRect.width) * 50;

    setTiltStyle(
      isFlipped
        ? {
            transform: `rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale(1.1)`, // Apply tilt if flipped
          }
        : {
            transform: `rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale(1)`, // Reset to default if not flipped
          }
    );
    

    // Calculate background position for coinBorder
    const backgroundX = 50 + (rotateY / 30) * 20; // Maximum ±50px movement
    const backgroundY = 50 + (rotateX / 30) * 20; // Maximum ±50px movement

    // Calculate shine position for coin shine
    const shineX = (rotateY / 30) * -100; // Max ±15px horizontal movement
    const shineY = (rotateX / 30) * 100; // Max ±15px vertical movement

    // Adjust image movement based on tilt
    const translateX = (rotateY / 30) * -10; // Max ±15px horizontal movement
    const translateY = (rotateX / 30) * 10; // Max ±15px vertical movement

    setBorderStyle({
      backgroundPosition: `${backgroundX}% ${backgroundY}%`,

    });

    setShineStyle({
      transform: `translate(calc(-50% + ${shineX}px), calc(-50% + ${shineY}px))`, // Center + translate + scale

    });

    setImgStyle({
      transform: `translate(calc(-50% + ${translateX}px), calc(-50% + ${translateY}px))`, // Center + translate + scale

    });

    // Holographic effect based on mouse position
    const px = Math.abs(Math.floor((100 / cardRect.width) * mouseX) - 100);
    const py = Math.abs(Math.floor((100 / cardRect.height) * mouseY) - 100);
    const lp = 0 + (px - 50) / 1.6;
    const tp = 0 + (py - 50) / 1.6;
    const px_spark = 50 + (px - 50) / 7;
    const py_spark = 50 + (py - 50) / 7;
    const p_opc = 20 + Math.abs((50 - px) + (50 - py)) * 1.5;

    // Set dynamic styles for :before and :after pseudo-elements 
    const styleContent = `
      .coinCard:hover:before {
        background-position: ${lp}% ${tp}%;
      }
      .coinCard:hover:after {
        background-position: ${px_spark}% ${py_spark}%;
        opacity: ${p_opc / 100};
      }
    `;
    styleTag.current.innerHTML = styleContent;
  };

  const handleMouseLeave = () => {
    //if (isFlipped) return; // Disable reset when flipped
    setTiltStyle(
      isFlipped
        ? {
            transform: `scale(1.1)`, // Apply tilt if flipped
            transition: "transform 1s ease", // Smooth easing for reset
          }
        : {
            transform: "rotateX(0deg) rotateY(0deg) scale(1)", // Reset to default if not flipped
            transition: "transform 1s ease", // Smooth easing for reset
          }
    );

    setBorderStyle({
      backgroundPosition: "50%, 50%", // Reset background position
      transition: "background-position 1s ease",
    });
    setImgStyle({
      transform: "translate(-50%, -50%)", // Reset scaling and position
      transition: "transform 1s ease",
    });
    setShineStyle({
      transform: "translate(-50%, -50%)", // Reset scaling and position
      transition: "transform 1s ease",
      transition: "opacity 1s ease",
    });
    // Clear the styles when mouse leaves
    styleTag.current.innerHTML = "";
  };




  const handleCardClick = (e) => {
    e.stopPropagation(); // Prevent the click from bubbling up to the document listener
  
    // Set the clicked card as active
    //setActiveCard((prevActiveCard) => (prevActiveCard === coin.title ? null : coin.title));
  };
  
  

  const resetCard = () => {
    setIsFlipped(false);
    setActiveCard(null); // Reset the active card in the parent component
    setTiltStyle({
      transform: "rotateX(0deg) rotateY(0deg) scale(1)",
      //transition: "transform 0.5s ease",
    });
    cardRef.current.style.boxShadow = "0 0 10px 2px #000000a2"; // Reset shadow
  };
  


  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (isFlipped && cardRef.current && !cardRef.current.contains(e.target)) {
        resetCard(); // Reset the card if clicked outside
      }
    };
  
    if (isFlipped) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }
  
    // Cleanup listener on unmount or when `isFlipped` changes
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isFlipped]);
  
  

  useEffect(() => {
    const isActive = activeCard === coin.title;

    if (isActive) {
      setIsFlipped(true); // Flip the card if it's active
      cardRef.current.style.transition = "transform 0.2s ";
      cardRef.current.style.transform = `
        scale(1.1)`;
        cardRef.current.style.boxShadow = "0 0 10px 2px rgba(255, 255, 255, 0.8), 0 0 20px 05px rgba(0, 200, 255, 0.5)";
      // Stop transform after 0.2 seconds
      setTimeout(() => {
        cardRef.current.style.transition = "none"; // Disable further transitions
      }, 200); // 200ms = 0.2 seconds
    } else {
      setIsFlipped(false); // Reset flipped state
      cardRef.current.style.transition = "transform 0.2s ";
      cardRef.current.style.transform = "scale(1)";
      cardRef.current.style.boxShadow = "0 0 10px 2px #000000a2";
      setTimeout(() => {
        cardRef.current.style.transition = "none"; // Disable further transitions
      }, 200); // 200ms = 0.2 seconds
    }
  }, [activeCard, coin.title]);
  

  return (
    <>
      <style ref={styleTag}></style> {/* This dynamically updates styles for :before and :after */}
      <div className="coinContainer">

        <div
          className={`coinCard ${isActive ? "active" : ""}`}
          ref={cardRef}
          onClick={handleCardClick}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          style={tiltStyle}
        >

          <div className="shine"
          style={shineStyle} // Apply dynamic background position>
          ></div>
          <div
            className="coinBorder"
            style={borderStyle} // Apply dynamic background position
          ></div>
          <span className="coinDescription">{coin.description}</span>
          <span className="coinTitle">{coin.title}</span>
          <span className="coinDescriptionBlur">{coin.description}</span>
          <span className="coinTitleBlur">{coin.title}</span>
          <div className="coinImgContainer">
          <img
            src={coin.img}
            className="coinImg"
            alt={coin.title}
            style={imgStyle} // Apply dynamic transform to the image
          />
          </div>
          <span className="coinPrice">${coin.price.toFixed(2)}</span>
          <span className="coinPriceBlur">${coin.price.toFixed(2)}</span>
          
        </div>

      </div>
    </>
  );
}

export default CoinCard;
