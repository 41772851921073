import React, { useState, useEffect, useRef, useContext } from 'react';
import { AppContext } from '../../App';
import './main.css';
import Header from '../header/Header';
import SideMenu from '../../components/sideMenu/SideMenu';
import Home from '../home/Home';
import Categories from '../categories/Categories';
import MyLibrary from '../MyLibrary';
import Bag from '../bag/Bag';
import Profile from '../profile/profile';
import Factory from '../factory/Factory';
import FactSideMenu from '../../components/factoryMenu/FactoryMenu';
//import PageLoader from "../../components/PageLoader";

function Main(props) {
  const { library, bag, category, currentCategory, updateCategory, animate, avatarName, updateAvatarName, } = useContext(AppContext);
  const [activeNavItem, setActiveNavItem] = useState('home'); // Default to 'home'

  const [active, setActive] = useState(false);
  const [games, setGames] = useState([]);
  const [coins, setCoins] = useState([]);
  const [bannerStyle, setBannerStyle] = useState({})

  const homeRef = useRef();
  const categoriesRef = useRef();
  const libraryRef = useRef();
  const bagRef = useRef();
  const proRef = useRef();
  const factRef = useRef();
  const factMenuRef = useRef();
  const sideMenuRef = useRef();
  const { cred, updateCredits } = props;
  const sections = [
    {
      name: 'home',
      ref: homeRef,
      active: true,
    },
    {
      name: 'categories',
      ref: categoriesRef,
      active: false,
    },
    {
      name: 'library',
      ref: libraryRef,
      active: false,
    },
    {
      name: 'bag',
      ref: bagRef,
      active: false,
    },
    {
      name: 'profile',
      ref: proRef,
      active: false,
    },
    {
      name: 'factory',
      ref: factRef,
      active: false,
    },
  ];

  const [menus, setMenus] = useState([
    {
      name: 'sideMenu',
      ref: sideMenuRef,
      isVisible: true, // Replacing 'active' with 'isVisible'
    },
    {
      name: 'factoryMenu',
      ref: factMenuRef,
      isVisible: false, // Replacing 'active' with 'isVisible'
    },
  ]);

  const handleToggleActive = () => {
    setActive(!active);
  };

  const handleSectionActive = target => {
    sections.forEach(section => {
      section.ref.current.classList.remove('active');
      if (section.ref.current.id === target) {
        section.ref.current.classList.add('active');
        if (target === 'factory') {
          setBannerStyle({ maxWidth: '100vw' }); // Change banner style when factory is active
        } else {
          setBannerStyle({ maxWidth: '1000px' }); // Reset style for other sections
        }
      }
    });
  };

  const handleShopClick = (target) => {
    setMenus(menus.map(menu => ({
      ...menu,
      isVisible: menu.name === 'sideMenu',
    })));
    handleSectionActive(target);
  };

  const handleNavItemClick = (target) => {
    setActiveNavItem(target); // Update the active nav item ID
  };

  const handleFactoryClick = () => {
    // Update menu visibility
    setMenus(menus.map(menu => {
      if (menu.name === 'sideMenu') {
        return { ...menu, isVisible: false };
      } else if (menu.name === 'factoryMenu') {
        return { ...menu, isVisible: true };
      }
      return menu;
    }));

    // Activate the factory section
    handleSectionActive('factory'); // Assuming 'factory' is the ID for the factory section
  };


  //const fetchData = () => {
  // fetch('../api/gamesData.json')
  //    .then(res => res.json())
  //    .then(data => {
  //      setGames(data);
  //   })
  //  .catch(e => console.log(e.message));
  //};
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('https://czbackend.herokuapp.com/api/CZMarket/items-for-sale');
      if (!response.ok) throw new Error('Failed to fetch items.');
      const data = await response.json();
      setGames(data);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };



  const fetchCoinData = () => {
    fetch('../api/coinData.json')
      .then(res => res.json())
      .then(data => {
        setCoins(data);
      })
      .catch(e => console.log(e.message));
  };

  useEffect(() => {
    fetchData();
    fetchCoinData();
  }, []);

  return (
    <main>

      <div className={`menuArea ${active ? 'active' : ''}`}>
        {menus.map(menu => {
          if (menu.name === 'sideMenu' && menu.isVisible) {
            return (
              <SideMenu
                active={active}
                onFactoryClick={handleFactoryClick}
                sectionActive={handleSectionActive}
                reference={sideMenuRef}
              />
            );
          } else if (menu.name === 'factoryMenu' && menu.isVisible) {
            return (
              <FactSideMenu
                active={active}
                sectionActive={handleSectionActive}
                reference={factMenuRef}
                category={category}
                avatarName={avatarName}
                updateAvatarName={updateAvatarName}
                animate={animate}
                onPoseToggleChange={props.handlePoseToggleChange}
                poseToggle={props.poseToggle}
                updateCategory={updateCategory}
                onShopClick={() => {
                  handleNavItemClick('1'); // Set the active nav item to '1'
                  handleShopClick(); // Additional logic for the Shop click
                }}
              />
            );
          }
          return null;
        })}
      </div>
      <div
        className={`banner ${active ? 'active' : ''}`}
        style={{ ...bannerStyle, minWidth: '1056px' }}
      >
        <Header
          toggleActive={handleToggleActive}
          cred={cred}
          active={active}
          reference={sideMenuRef}
          onFactoryClick={() => {
            handleFactoryClick();
            handleNavItemClick('factory');
          }}
          onShopClick={(target) => {
            handleShopClick(target);
            handleNavItemClick(target);
          }}
          sectionActive={handleSectionActive}
          activeNavItem={activeNavItem}
          setActiveNavItem={setActiveNavItem}
        />


        <div className="contain-fluid">
          {games && games.length > 0 && (
            <Home coins={coins} games={games} reference={homeRef} updateCredits={updateCredits} />
          )}
          {games && games.length > 0 && (
            <Categories games={games} reference={categoriesRef} updateCredits={updateCredits} />
          )}
          {games && games.length > 0 && (
            <MyLibrary games={library} reference={libraryRef} />
          )}
          {games && games.length > 0 && (
            <Bag games={bag} reference={bagRef} />
          )}

          <Profile
            reference={proRef}
            updateCredits={updateCredits}
          />
          <Factory
            reference={factRef}
            animate={animate}
            avatarName={avatarName}
            updateAvatarName={updateAvatarName}
            onPoseToggleChange={props.handlePoseToggleChange}
            poseToggle={props.poseToggle}
            category={props.category}
            updateCategory={props.updateCategory}
            currentCategory={props.currentCategory}
          />
        </div>

      </div>

    </main>
  );
}

export default Main;
